<template>
  <div class="cont">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="search-box">
      <el-row class="search-row">
        <el-row class="search-item">
          <date-packer size="medium" :datePacker="dateRange" @setDatePacker="getDatePacker" format="yyyy-MM-dd" align="left"></date-packer>
        </el-row>
      </el-row>
    </el-row>
    <div class="title">
      <h3>营业额概况</h3>
      <span>统计说明</span>
      <el-tooltip class="item" effect="light" placement="top-start">
        <div slot="content">
          1. 本数据仅统计房价，客房消费与违约金不会被统计。<br>
          2. 钟点房默认为0.5间夜，若想要修改，请前往系统设置-统计设置进行修改。<br>
          3. 总房费是指全日房与钟点房的房费之和，累计出售间夜数是指全日房间夜数与钟点房间夜数之和。<br>
          4. 总可售房间数是指您系统中每天所有房间数量的和。若您在房态中将某些房间关房，则关房的房间默认是不可售的，
          不会被统计，如果您要统计，请前往系统设置-统计设置进行修改。<br>
          5. RevPAR代表了这段时间里，您平均每间房（无论是否售出）给您的带来的房费收入。'
        </div>

      </el-tooltip>
    </div>
    <el-row class="card-box" type="flex">
      <dl v-for="(item, index) in parseStatisticsTotal" :key="index">
        <dt>
          <div :class="['image', `image${index+1}`]"></div>
        </dt>
        <dd>
          <el-row type="flex">
            <h6>{{ item.title }}<p>{{ item.subTitle }}</p>
            </h6>
            <el-tooltip class="item" effect="dark" :content="item.tip" placement="top-start">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </el-row>
          <span>
            {{ item.code === 'avgRoomPrice' || item.code === 'avgRoomRevenue' ? '￥' : '' }}
            {{ item.total }}
            {{ item.code === 'occupancyRate' ? '%' : '' }}
          </span>
        </dd>
      </dl>
    </el-row>
    <div class="title m-top-50">
      <h3>概况折线图</h3>
    </div>
    <div class="chart-box">
      <el-button-group class="m-bottom-10">
        <el-button @click="changeChart(1)" :type="this.chartType === 1 ? 'primary' : ''">平均房价</el-button>
        <el-button @click="changeChart(2)" :type="this.chartType === 2 ? 'primary' : ''">入住率</el-button>
        <el-button @click="changeChart(3)" :type="this.chartType === 3 ? 'primary' : ''">平均客房收益</el-button>
        <el-button @click="changeChart(4)" :type="this.chartType === 4 ? 'primary' : ''">间夜数</el-button>
      </el-button-group>
      <div class="chart-wrap">
        <div class="chart" id="chart"></div>
      </div>
    </div>
    <div class="title m-top-50">
      <h3>报表明细<span v-if="dateRange.length > 0">{{ dateRange[0] + '~' +dateRange[1] }}</span></h3>
    </div>
    <div class="chart-box">
      <el-row type="flex" justify="space-between">
        <el-tabs v-model="activeName" @tab-click="changeReportDetail">
          <el-tab-pane label="房费报表" name="roomFeeDetail"></el-tab-pane>
          <el-tab-pane label="间夜报表" name="dailyRoomNight"></el-tab-pane>
          <el-tab-pane label="入住率报表" name="occupancyRate"></el-tab-pane>
          <el-tab-pane label="RevPAR报表" name="roomDailyRevPAR"></el-tab-pane>
        </el-tabs>
        <div>
          <el-button type="primary" @click="handleExport">导出报表</el-button>
        </div>
      </el-row>
      <el-table ref="table" :data="tableData" :summary-method="getSummaries" :span-method="handleRowspan" border show-summary>
        <el-table-column prop="roomType" label="房型"></el-table-column>
        <el-table-column v-if="activeState" prop="roomName" label="房间"></el-table-column>
        <el-table-column prop="totalAmount" label="合计"></el-table-column>
        <el-table-column :label="date" :prop="date" v-for="(date, index) in dateList" :key="index"></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import {
  getTotalDetail,
  getLineChart,
  getReportDetail,
  exportRoomSalesSituation,
} from "@/api/pms/statistics/roomBusiness";
import { exportFile } from "@/common/js/common";
import { mapState } from "vuex";
import { dateFactory } from "@/utils/dateFactory";
export default {
  name: "roomBusinessSummary",
  data() {
    return {
      crumbs: new Map([["PMS"], ["统计中心"], ["客房营业汇总"]]),
      dateRange: [],
      statisticsTotal: {
        avgRoomPrice: 0,
        occupancyRate: 0,
        avgRoomRevenue: 0,
        saleRoomNightsNum: 0,
        avgSaleRoomNightsNumber: 0,
      },
      chartType: 1,
      activeName: "roomFeeDetail",
      activeState: true,
      tableData: [],
      dateList: [],
      currentIndex: 0,
    };
  },
  computed: {
    ...mapState(["hotelInfo"]),
    parseStatisticsTotal() {
      const totalArr = [
        {
          title: "平均房价（元）",
          subTitle: "ADR",
          tip: "平均房价=总房费/累计出售间夜数",
          code: "avgRoomPrice",
          total: 0,
        },
        {
          title: "入住率",
          subTitle: "Occ",
          tip: "入住率 = 累计出售间夜数/总可售房间数",
          code: "occupancyRate",
          total: 0,
        },
        {
          title: "平均客房收益",
          subTitle: "RevPAR",
          tip: "平均客房收益 = 平均房价 x 入住率",
          code: "avgRoomRevenue",
          total: 0,
        },
        {
          title: "累计出售间夜数",
          subTitle: "-",
          tip: "统计已预订、已入住、已退房的钟点房与全日房间夜数",
          code: "saleRoomNightsNum",
          total: 0,
        },
        {
          title: "平均每日间夜数",
          subTitle: "-",
          tip: "平均每日间夜数=累计出售间夜数/统计天数",
          code: "avgSaleRoomNightsNumber",
          total: 0,
        },
      ];
      for (const k in this.statisticsTotal) {
        totalArr.find((i) => i.code === k).total = this.statisticsTotal[k];
      }
      return totalArr;
    },
  },
  mounted() {
    this.dateRange = [
      dateFactory.getMonthDate("start", false),
      dateFactory.dateFormat(false),
    ];
    this.getGeneralSituation();
    this.getLineChart();
    this.changeReportDetail();
  },
  methods: {
    // 获取订单时间
    getDatePacker(val) {
      this.dateRange = val;
      this.getGeneralSituation();
      this.getLineChart();
      this.changeReportDetail();
    },
    // 获取概况统计
    getGeneralSituation() {
      const params = {
        hotelId: this.hotelInfo.id,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1],
      };
      getTotalDetail(params).then(({ success, records }) => {
        if (success) this.statisticsTotal = records;
      });
    },
    // 改变概况图表
    changeChart(val) {
      this.chartType = val;
      this.getLineChart();
    },
    // 获取概况折线
    getLineChart() {
      const params = {
        hotelId: this.hotelInfo.id,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1],
        type: this.chartType,
      };
      const nameMap = new Map([
        [1, "平均房价"],
        [2, "入住率"],
        [3, "平均客房收益"],
        [4, "间夜数"],
      ]);
      const option = {
        grid: {
          left: "5%",
          right: "5%",
          top: "14%",
          bottom: "16%",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: { type: "shadow" },
          formatter: (params) => {
            return `${params[0].name}\n ${nameMap.get(this.chartType)}：$${
              params[0].value
            }`;
          },
        },
        xAxis: {
          type: "category",
          axisLabel: { rotate: 45 },
          data: [],
          axisLine: { lineStyle: { color: "#D5D5D5" } },
        },
        yAxis: {
          type: "value",
          axisLine: { lineStyle: { color: "#D5D5D5" } },
        },
        series: [
          {
            data: [],
            type: "line",
            itemStyle: {
              normal: { color: "#087FFD", lineStyle: { color: "#087FFD" } },
            },
          },
        ],
      };
      getLineChart(params).then(({ success, records }) => {
        if (success) {
          records.forEach((item) => {
            option.xAxis.data.push(item.date);
            option.series[0].data.push(item.value);
          });
          this.$echarts
            .init(document.getElementById("chart"))
            .setOption(option);
        }
      });
    },
    // 改变报表明细
    changeReportDetail() {
      this.activeState =
        this.activeName === "roomFeeDetail" ||
        this.activeName === "dailyRoomNight";
      const params = {
        hotelId: this.hotelInfo.id,
        beginDateStr: this.dateRange[0],
        endDateStr: this.dateRange[1],
      };
      getReportDetail(this.activeName, params).then(({ success, records }) => {
        if (success) {
          const { list, dates } = { ...records };
          const tableData = [];
          let startIndex = 0; // 起始指针
          let currIndex = 0; // 当前指针
          for (const v1 of list) {
            startIndex = currIndex; // 循环完当前房型后更新起始指针
            // 如果当前选项是房费报表和间夜报表
            if (this.activeState) {
              for (const v2 of v1.list) {
                const row = {};
                row.roomType = v1.name;
                row.roomName = v2.name;
                row.totalAmount = v2.totalAmount;
                currIndex++;
                row.startIndex = startIndex; // 记录同一房型的起始下标
                row.roomSum = v1.list.length; // 记录同一房型的房间总数
                for (const [idx, v3] of dates.entries()) {
                  row[v3] = v2.dailyAmounts[idx];
                }
                tableData.push(row);
              }
            } else {
              const row = {};
              row.roomType = v1.name;
              row.totalAmount = v1.totalAmount;
              for (const [idx, v2] of dates.entries()) {
                row[v2] = v1.dailyAmounts[idx];
              }
              tableData.push(row);
            }
          }
          this.dateList = dates;
          this.tableData = tableData;
        }
      });
    },
    // 合并行
    handleRowspan({ row, column, rowIndex, columnIndex }) {
      if (this.activeState) {
        if (columnIndex === 0) {
          if (rowIndex === row.startIndex) {
            return { rowspan: row.roomSum, colspan: 1 };
          } else {
            return { rowspan: 0, colspan: 0 };
          }
        }
      }
    },
    //总合计方法
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 1) {
          sums[index] = "";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr, rowIndex) => {
            const value = Number(curr);
            if (
              !isNaN(value) &&
              rowIndex ===
                data[rowIndex].roomSum + data[rowIndex].startIndex - 1
            ) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "无数据";
        }
      });
      sums[2] = parseFloat(sums[2]).toFixed(2);
      return sums;
    },
    // 导出报表
    handleExport() {
      if (this.tableData.length === 0) {
        return this.$message({showClose: true,message:"空表格不能导出",type: 'error'});
      }
      const params = {
          hotelId: this.hotelInfo.id,
          beginDateStr: this.dateRange[0],
          endDateStr: this.dateRange[1],
        },
        maps = new Map([
          ["roomFeeDetail", "房费"],
          ["roomDailyRevPAR", "平均房价收益"],
          ["dailyRoomNight", "间夜"],
          ["occupancyRate", "入住率"],
        ]),
        query = `/export${this.activeName.replace(/^\S/, (s) =>
          s.toUpperCase()
        )}List`;
      exportRoomSalesSituation(query, params).then((res) => {
        exportFile(res, `客房营业汇总${maps.get(this.activeName)}报表`);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.cont {
  .title {
    margin: 35px 0 10px 0;
    h3 {
      font-size: 18px;
      display: inline;
    }
    span {
      color: #b9b9b9;
      font-weight: normal;
      font-size: 12px;
      margin-left: 6px;
    }
    i {
      color: #b9b9b9;
      font-size: 12px;
    }
  }
  .card-box {
    background: #ffffff;
    dl {
      display: flex;
      align-items: center;
      padding: 30px 0 30px 30px;
      width: 33.33%;
      position: relative;
      dt {
        margin: 0 20px 0 0;
        .image {
          width: 48px;
          height: 48px;
          background-size: 100% 100%;
        }
      }
      dt .image1 {
        background-image: url("../../../assets/600.png");
      }
      dt .image2 {
        background-image: url("../../../assets/601.png");
      }
      dt .image3 {
        background-image: url("../../../assets/602.png");
      }
      dt .image4 {
        background-image: url("../../../assets/603.png");
      }
      dt .image5 {
        background-image: url("../../../assets/604.png");
      }
      dd {
        h6 {
          font-size: 14px;
          color: #999999;
          margin-bottom: 10px;
          font-weight: normal;
          p {
            font-size: 12px;
            color: #d5d5d5;
            margin: 3px 0 0 0;
          }
        }
        .el-icon-info {
          color: #b9b9b9;
          font-size: 12px;
          margin: 3px 0 0 3px;
        }
        span {
          font-size: 28px;
          color: #087ffd;
        }
      }
    }
    dl:before {
      position: absolute;
      content: "";
      width: 1px;
      height: 60px;
      background-color: #ccced9;
      right: 0;
      top: 30px;
    }
  }
  .chart-box {
    .chart-wrap {
      width: 100%;
      background: #ffffff;
      .chart {
        width: 1600px;
        height: 420px;
      }
    }
  }
}
</style>
