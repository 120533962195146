import request from "@/api/service";

const api = {
   totalDetail: '/pms/roomSalesSituation/getTotalDetail',
   lineChart: '/pms/roomSalesSituation/getLineChart',
   roomFeeDetail: '/pms/roomSalesSituation/getRoomFeeDetailList',
   dailyRoomNight: '/pms/roomSalesSituation/getDailyRoomNightList',
   occupancyRate: '/pms/roomSalesSituation/getOccupancyRateList',
   roomDailyRevPAR: '/pms/roomSalesSituation/getRoomDailyRevPARList',
   roomSalesSituation: '/pms/roomSalesSituation'
}

export function getTotalDetail(params) {
   return request({
      url: api.totalDetail,
      method: 'POST',
      data: params
   })
}

export function getLineChart(params) {
   return request({
      url: api.lineChart,
      method: 'POST',
      data: params
   })
}

export function getReportDetail(type, params) {
   return request({
      url: api[type],
      method: 'POST',
      data: params
   })
}

export function exportRoomSalesSituation(query, params) {
   return request({
      url: api.roomSalesSituation + query,
      method: 'GET',
      params,
      responseType: 'blob'
   })
}
