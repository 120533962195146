/* 日期处理库 */
export class DateFactory {
   constructor(date = new Date()) {
      this.date = new Date(date)
      this.year = this.date.getFullYear()
      this.nowDayOfWeek = this.date.getDay() - 1;
      this.month = this.date.getMonth()
      this.day = this.date.getDate()
      this.weekDay = this.date.getDay()
      this.hours = this.date.getHours()
      this.minutes = this.date.getMinutes()
      this.seconds = this.date.getSeconds()
      this.time = this.date.getTime()
   }
   /*
    * Date()对象格式化转换
    * @falg 是否显示时分秒 值true=显示 false=不显示
    * return yyyy-MM-dd HH:mm:ss
   */
   dateFormat(falg = true) {
      let MM = this.month + 1 < 10 ? "0"+(this.month + 1) : this.month + 1
      let dd = this.day < 10 ? "0" + this.day : this.day
      let HH = this.hours < 10 ? "0" + this.hours : this.hours
      let mm = this.minutes < 10 ? "0" + this.minutes : this.minutes
      let ss = this.seconds < 10 ? "0" + this.seconds : this.seconds
      let formatDate = this.year + "-" + MM + "-" + dd
      this.constructor()
      return falg ? formatDate + " "+ HH + ":" + mm + ":" + ss : formatDate
   }
   /*
    * 时间戳格式化转换
    * @timestamp *时间戳
    * return yyyy-MM-dd HH:mm:ss
   */
   timestampFormat(timestamp, flag) {
      let date = new Date(parseInt(timestamp) * 1000);
      this.constructor(date)
      return this.dateFormat(flag)
   }
   /*
    * yy-MM-dd HH:mm:ss格式化转换
    * @time *日期 格式=yy-MM-dd HH:mm:ss
    * return 时间戳
   */
   changeTimestamp(date) {
      this.constructor(date.replace(/-/g,'/'))
      let timeStamp = this.time.toString().substr(0, 10)
      this.constructor()
      return timeStamp
   }
   /*
    * 获取距离当前时间某天数的日期
    * @days 天数 值：0=今天;1=明天;-1=昨天...
    * @falg 是否显示时分秒
    * return 格式：yyyy-MM-dd HH:mm:ss
   */
   getDistanceToday(days = 0, falg = true) {
      this.date.setDate(this.date.getDate() + days)
      this.constructor(this.date)
      return this.dateFormat(falg)
   }
   /*
    * 获取距离指定时间某天数的日期
    * @date 指定日期 格式:yyyy-MM-dd HH:mm:ss
    * @method +或-
    * @days 天数 类型:int
    * @flag 是否只留年月日
    * return 格式：yyyy-MM-dd HH:mm:ss
   */
   getDistanceAppoint(date, method = '+', days, flag) {
      let timestamp = Date.parse(date.replace(/-/g, '/'));
      if(method === '+') timestamp = timestamp / 1000 + 24 * 60 * 60 * days;
      if(method === '-') timestamp = timestamp / 1000 - 24 * 60 * 60 * days;
      return this.timestampFormat(timestamp, flag);
   }
   /*
    * 获取上周/本周的开始(周一)/结束(周日)日期
    * @type 周类型 值：the=本周;last=上周;next=下周
    * @fromDate 开始日期或结束日期 值：start=周一;end=周日
    * @falg 是否显示时分秒
   */
   getWeekDate(type = 'the', fromDate = 'start', falg = true) {
      let num = 0
      if (type === 'last') num = fromDate === 'start' ? 7 : 1
      if (type === 'the') num = fromDate === 'start' ? 0 : -(6 - this.nowDayOfWeek)
      if (type === 'next') num = fromDate === 'start' ? -7 : -13
      let date = new Date(this.year, this.month, this.day - this.nowDayOfWeek - num);
      this.constructor(date)
      return this.dateFormat(falg)
   }
   /*
    * 获取本月的开始/结束日期
    * @fromDate 开始或结束日期
   */
   getMonthDate(fromDate = 'start', falg = true) {
      let date
      if (fromDate === 'start') date = new Date(this.year, this.month, 1)
      if (fromDate === 'end') date = new Date(this.year, this.month, this.getMonthDays(this.month));
      this.constructor(date)
      return this.dateFormat(falg);
   }
   /*
    * 获得某月的天数
    * @month *当前月份
   */
   getMonthDays(month) {
      let monthStartDate = new Date(this.year, month, 1);
      let monthEndDate = new Date(this.year, month + 1, 1);
      let days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
      return days;
   }
   /*
    * 获取本周日期列表
    * return 日期数组
   */
   getWeekList() {
      let arr = []
      this.date = new Date()
      for (let i = 1; i < 8; i++) {
         this.date = new Date(this.time + (i - this.weekDay) * 24 * 60 * 60 * 1000)
         this.constructor(this.date)
         arr.push(this.dateFormat(false))
      }
      return arr
   }
   /*
    * 比较两个时间大小
    * @startTime *起始时间 格式:标准时间/yyyy-MM-dd HH:mm:ss
    * @endTime *结束时间
    * return true/false
   */
   compareTime(startTime, endTime) {
      return new Date(startTime).getTime() > new Date(endTime).getTime()
   }
   /*
    * 获取指定区间的全部日期
    * @startDate *起始日期 格式：yyyy-MM-dd
    * @endDate *结束日期
    * return []日期列表
   */
   getDateRange(startDate, endDate) {
      let dateRange = [];
      let i = 0;
      while(startDate <= endDate){
         dateRange[i] = startDate;
         let nextDate = new Date(startDate).getTime() + ( 24 * 60 * 60 * 1000);
         this.constructor(nextDate)
         startDate = this.dateFormat(false)
         i++;
      }
      return dateRange;
   }
   /*
    * 获取两个日期距离天数
    * @startDate *起始日期
    * @endDate *截止日期
    * bool *精确时分秒
    * return 天数
   */
   getDistanceDays(startDate, endDate,bool) {
      let days = new Date(endDate).getTime() - new Date(startDate).getTime()
      if(bool) return days
      return parseInt(days / (1000 * 60 * 60 * 24));
   }
}

export const dateFactory = new DateFactory()
